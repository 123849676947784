import { Typography } from "antd";
import React from "react";
interface IProps {
  isSmallLoading?: boolean;
  padding?: string;
  msg?: string;
}

const Loader = (props: IProps) => {
  return (
    <div>
      {props.isSmallLoading ? (
        <Typography.Text
          style={{
            opacity: "0.5",
            fontSize: "1rem",
            fontWeight: "300",
            padding: `${props.padding}`,
          }}
        >
          {props.msg ? props.msg : "Loading..."}
        </Typography.Text>
      ) : (
        <Typography.Text
          style={{
            opacity: "0.5",
            fontSize: "2rem",
            fontWeight: "300",
            padding: `${props.padding}`,
          }}
        >
          {props.msg ? props.msg : "Loading..."}
        </Typography.Text>
      )}
    </div>
  );
};

export default Loader;
