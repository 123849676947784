 import { put } from 'redux-saga/effects'

 import { fetchConfigDetailsSuccess, fetchConfigDetailsFailure } from '../actions/CommonAction'
 import { api } from '../utils/fetch-json'
 
 export function* fetchConfigDetails(action) {
   try {
     const response = yield api.get('app','')
     if (response && response.data && response.data.currentUser) {
       yield put(fetchConfigDetailsSuccess(response.data))
     } else if (response.error) {
       yield put(fetchConfigDetailsFailure(response.error))
     }
   } catch (error) {
     yield put(fetchConfigDetailsFailure(error))
   }
 }
 