import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  Modal,
  Typography,
  Space,
  InputRef,
  Radio,
  RadioChangeEvent,
  Row,
  Col,
  Tooltip,
  App,
} from "antd";
import { Navigate } from "react-router-dom";
import { api } from "../../utils/fetch-json";
import CustomDatePicker from "./CustomDatePicker";
import { RootStateOrAny, shallowEqual, useSelector } from "react-redux";
import { ACCESS_DENIED_MESSAGE } from "../../utils/Const";

const { Text, Link } = Typography;
interface IProps {
  updateToken: (type: string, token: string) => void;
}

export default function AddApiKey(props: IProps) {
  const { notification } = App.useApp();
  const [apiType, setApiType] = useState("apikey");
  const [apiName, setApiName] = useState<string>("");
  const [apiNameError, setApiNameError] = useState<boolean>(false);
  const [apiDuration, setApiDuration] = useState<string>("");
  const [apiDurationError, setApiDurationError] = useState<boolean>(false);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const [durationInputType, setDurationInputType] = useState("Hour");

  const { authorizedAtoms } = useSelector(
    (state: RootStateOrAny) => ({
      authorizedAtoms: state.commonReducer.authorizedAtoms,
    }),
    shallowEqual
  );

  useEffect(() => {
    const { current } = inputRef;
    if (current && modal) {
      callbackFn(current);
    }
  }, [modal]);

  const callbackFn = (current: InputRef) => {
    setTimeout(() => {
      current.focus({});
    }, 1);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const onCancel = () => {
    setApiName("");
    setApiNameError(false);
    setApiDuration("");
    setApiDurationError(false);
    setShowError(false);
    setLoading(false);
    setDurationInputType("Hour");
    toggle();
  };

  const onSave = () => {
    const url = `add-api-key`;
    let pattern = new RegExp(/^[a-zA-Z]+[a-zA-Z0-9_]*$/i);
    let durationPattern = new RegExp(/^[1-9]\d*$/i);
    if (apiName && pattern.test(apiName)) {
      setApiNameError(false);
    } else {
      setApiNameError(true);
      setApiDurationError(false);
      setShowError(false);
      setLoading(false);
      return;
    }
    if (apiDuration !== "" && durationPattern.test(apiDuration)) {
      setApiDurationError(false);
    } else {
      setApiDurationError(true);
      setShowError(false);
      setLoading(false);
      return;
    }
    const body = {
      type: apiType,
      name: apiName,
      duration: apiDuration + "h",
    };
    api
      .post(url, body)
      .then((res) => {
        if (res.error) {
          setShowError(res.error);
          notification.error({
            message: `${res.error}`,
            placement: "bottomRight",
          });
          setLoading(false);
          return;
        }
        props.updateToken("add", res.data);
        setDurationInputType("Hour");
        toggle();
        setShowError(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 400) {
          notification.error({
            message: `${error?.response?.data?.title}`,
            placement: "bottomRight",
          });
          setShowError(error.response.data.title);
        } else if (error.response.status === 401) {
          loginWithRedirection();
        }
      });
  };

  const loginWithRedirection = () => {
    let redirectUrl = window.location.href;
    return <Navigate to={`/`} />;
  };
  const onSwitchChange = ({ target: { value } }: RadioChangeEvent) => {
    setDurationInputType(value);
  };
  const onDateSelect = (evt: any) => {
    const selectedDateValue = evt.valueOf();
    const currentDateValue = new Date().valueOf();
    const totalHours =
      (selectedDateValue - currentDateValue) / (1000 * 60 * 60);
    const roundedValue = Math.round(totalHours).toString();
    setApiDuration(roundedValue);
  };

  const options = [
    { label: "Hour(s)", value: "Hour" },
    { label: "Date", value: "Date" },
  ];

  const isAddApiKeyAllow =
    authorizedAtoms?.["home-app-api-key-create"]?.["allow"];

  return (
    <>
      <Tooltip
        title={isAddApiKeyAllow ? "Add apikey" : `${ACCESS_DENIED_MESSAGE}`}
      >
        <Link disabled={!isAddApiKeyAllow} onClick={onCancel}>
          + Add API Key
        </Link>
      </Tooltip>
      <Modal
        forceRender
        title={"Add API Key"}
        open={modal}
        onCancel={onCancel}
        centered
        footer={[
          <Button
            loading={loading}
            key="save_button_key"
            type="primary"
            onClick={() => {
              setLoading(true);
              onSave();
            }}
          >
            Save
          </Button>,

          <Button key="cancel_button_key" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
        mask
        keyboard={false}
        destroyOnClose={true}
        getContainer={() => document.body}
      >
        <Space direction="vertical" style={{ width: "100%", height: "130px" }}>
          <div>
            <Input
              placeholder={"API Name"}
              ref={inputRef}
              required
              name="apiName"
              value={apiName}
              onChange={(e) => setApiName(e.target.value)}
              // invalid={apiNameError}
            />
            <Text disabled>
              <small>
                {"Please use alpha numeric and underscore character only"}
              </small>
            </Text>
            {apiNameError && (
              <Text type="danger" style={{ display: "block" }}>
                <small>{"Please enter valid api token name"}</small>
              </Text>
            )}
          </div>
          <Row style={{ width: "100%" }}>
            <Col span="16">
              <div>
                {durationInputType === "Date" ? (
                  <CustomDatePicker onDateSelect={onDateSelect} />
                ) : (
                  <>
                    <Input
                      placeholder={"Duration in hour(s)"}
                      required
                      type="number"
                      min="0"
                      name="apiDuration"
                      value={apiDuration}
                      onChange={(e) => setApiDuration(e.target.value)}
                      // invalid={apiDurationError.toString()}
                    />
                    <Text disabled>
                      <small>{"Please use numeric only"}</small>
                    </Text>
                  </>
                )}
                {apiDurationError && (
                  <Text type="danger" style={{ display: "block" }}>
                    <small>{"Please provide correct duration value"}</small>
                  </Text>
                )}
              </div>
            </Col>
            <Col style={{ paddingLeft: "6px" }} span="8">
              <Radio.Group
                options={options}
                onChange={onSwitchChange}
                value={durationInputType}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
          </Row>
          {showError && (
            <Text type="danger">
              <small>{showError}</small>
            </Text>
          )}
        </Space>
      </Modal>
    </>
  );
}
