import AppContainer from "./containers/AppContainer";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import { ModernAuth } from "modern-auth";
import { useEffect, useState } from "react";
import axios from "axios";
import { AnalyticsProvider } from "modern-analytics";

function App() {
  const [config, setConfig] = useState<any>(null);
  const [authInfoUrl, setAuthInfoUrl] = useState("");

  const fetchConfigDetailsOnly = (details: { [x: string]: any }) => {
    return Object.keys(details)
      .filter((key) => !key.includes("heimdallBaseUrl"))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: details[key],
        });
      }, {});
  };

  useEffect(() => {
    let url = "api/config";
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          setAuthInfoUrl(res.data.heimdallBaseUrl);
          setConfig(fetchConfigDetailsOnly(res.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {config && (
        <Provider store={store}>
          <Router basename={"home"}>
            <ModernAuth oidc_config={config}>
              <AnalyticsProvider>
                <AppContainer authInfoUrl={authInfoUrl} />
              </AnalyticsProvider>
            </ModernAuth>
          </Router>
        </Provider>
      )}
    </>
  );
}

export default App;
