import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import CommonLogo from "../../assets/dataOS-home-logo.svg";
import LensLogo from "../../assets/dataOS-lens-logo.svg";
import UserInfo from "./UserInfo";
import { Button, Dropdown, Menu, Row, Typography } from "antd";
import { RootStateOrAny, shallowEqual, useSelector } from "react-redux";
import Icon from "@ant-design/icons";
import QuestionCircleIcon from "../../assets/question-circle.svg";
import { ReactComponent as OpenInNewOutlined } from "../../assets/OpenInNew-outlined.svg";
import DocsLogo from "../../assets/doc.svg";

interface IProps {
  toggleTheme: () => void;
}

const { Text, Link } = Typography;

const CommonNavbar = (props: IProps) => {
  const location = useLocation();
  //const [logo, setLogo] = useState(CommonLogo);
  const [activeMenu, setActiveMenu] = useState<string>("apps");

  const { user } = useSelector(
    (state: RootStateOrAny) => ({
      user: state.commonReducer.user,
    }),
    shallowEqual
  );

  const currentUserId = JSON.parse(
    localStorage.getItem("userInfo") ?? "{}"
  )?.id;

  const navbarItem = [
    { name: "apps", label: "Apps", to: "/apps" },
    { name: "profile", label: "Profile", to: `/profile/${currentUserId}` },
  ];

  const setActiveMenuValue = () => {
    let path = location.pathname;
    if (path.includes(`/apps`) || (path.length === 1 && path === "/"))
      setActiveMenu("apps");
    else if (path.includes(`/profile`)) setActiveMenu("profile");
    else setActiveMenu("");
  };

  useEffect(() => {
    document.title = "Home | DataOS";
    if (location.pathname) setActiveMenuValue();
  }, [location.pathname]);

  let activeStyle = {
    color: "#1890ff",
    padding: "2px 10px 0px",
  };

  let inactiveStyle = {
    padding: "2px 10px 0px",
  };

  const supportDropdownMenu = (
    <Menu
      items={[
        {
          key: "datsOSDocs",
          label: (
            <Link target="_blank" href="https://dataos.info/interfaces/">
              <Text style={{ display: "flex", alignItems: "center" }}>
                <img
                  className="docs-logo"
                  src={DocsLogo}
                  height="14px"
                  style={{ marginRight: "3px" }}
                  alt="doc-logo"
                />
                Docs
                <Icon
                  style={{ marginLeft: "3px" }}
                  component={OpenInNewOutlined}
                />
              </Text>
            </Link>
          ),
        },
      ]}
    />
  );

  return (
    <div
      style={{
        maxWidth: "1440px",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "0 20px 0 17px",
      }}
    >
      <Row align="middle" justify="space-between">
        {/* <div className="logo" style={{ cursor: "pointer" }}>
          <img
            src={CommonLogo}
            width="163"
            height="16"
            alt={"DataOS"}
            style={{ marginTop: "4px", marginLeft: "-10px" }}
          />
        </div> */}
        <div className="navbar-container-custom">
          <Dropdown
            menu={{ items: [] }}
            //trigger={["click"]}
            // placement="bottomLeft"
             overlayClassName="navbar-dropdown"
             open={false}
            
          >
            <div
              className="logo"
              style={{ cursor: "pointer" }}
              data-testid="dropdown"
            >
              <img
                src={CommonLogo}
                width="163"
                alt={"DataOS"}
                height="16"
                style={{ marginTop: "-2px", marginLeft: "-2px" }}
              />
            </div>
          </Dropdown>
        </div>
        {navbarItem && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "auto",
            }}
          >
            {navbarItem.map(({ label, name, to }, index) => {
              return (
                <React.Fragment key={name}>
                  <NavLink
                    style={activeMenu === name ? activeStyle : inactiveStyle}
                    to={to}
                    className="utility-underline"
                  >
                    {label}
                  </NavLink>
                </React.Fragment>
              );
            })}
          </div>
        )}
        <div className="help-btn">
          <Dropdown overlay={supportDropdownMenu} trigger={["click"]}>
            <Button type="text" shape="circle" style={{ padding: "0 8px" }}>
              <img
                className="help-btn__icon"
                src={QuestionCircleIcon}
                alt="Help icon"
                height="20px"
                width="20px"
              />
            </Button>
          </Dropdown>
        </div>
        <div key={"userinfo"}>
          <UserInfo toggleTheme={props.toggleTheme} />
        </div>
      </Row>
    </div>
  );
};

export default React.memo(CommonNavbar);
