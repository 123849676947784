import React, { Fragment, useState } from "react";
import { Table } from "reactstrap";
import { Navigate } from "react-router-dom";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useExpanded,
  usePagination,
  useRowSelect,
  Column,
} from "react-table";
import Moment from "react-moment";
import AddApiKey from "./AddApiKey";
import copy from "copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Tooltip,
  Row,
  Col,
  Space,
  Typography,
  Input,
  Divider,
  Button,
  App,
} from "antd";
import {
  ExclamationCircleOutlined,
  SearchOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import NothingToShow from "../../common/NothingToShow";
import { userTokensType } from "./RenderUserProfileEditMode";
import { RootStateOrAny, shallowEqual, useSelector } from "react-redux";
import axios from "axios";
import { ACCESS_DENIED_MESSAGE } from "../../utils/Const";
import message from "../../utils/message";

const { Text } = Typography;

interface CustomTablePropTypes {
  columns: Array<Column>;
  data: Array<userTokensType>;
  updateToken: (type: string, token: string) => void;
}
interface TokensPropTypes {
  data: Array<userTokensType>;
  updateToken: (type: string, token: string) => void;
}
interface GlobalFilterType {
  globalFilter: string;
  setGlobalFilter: (filterValue: string | undefined) => void;
  updateToken: (type: string, token: string) => void;
}
// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  updateToken,
}: GlobalFilterType) {
  return (
    <Fragment>
      <div className="search-bar">
        <Input
          placeholder="search"
          value={globalFilter || ""}
          onChange={(e) => {
            setGlobalFilter(e.target.value || undefined);
          }}
          prefix={
            <SearchOutlined
              style={{ color: "#ccccccc7" }}
              translate={undefined}
            />
          }
        />
      </div>
      <Divider type="vertical" style={{ margin: "0 20px" }} />
      <AddApiKey updateToken={updateToken} />
    </Fragment>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, setFilter } }: any) {
  return (
    <Fragment>
      <div className="search-bar">
        <Input
          placeholder="search"
          value={filterValue || ""}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          prefix={
            <SearchOutlined
              style={{ color: "#ccccccc7" }}
              translate={undefined}
            />
          }
        />
      </div>
    </Fragment>
  );
}

// Our table component
function CustomTable({ columns, data, updateToken }: CustomTablePropTypes) {
  const { modal } = App.useApp();
  const filterTypes: any = React.useMemo(
    () => ({
      text: (rows: any, id: number, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  function renderTime(cell: any) {
    let updatedDate = {};
    updatedDate = <Moment format="MMMM DD, YYYY HH:mm:ss">{cell.value}</Moment>;
    return updatedDate;
  }

  const [shownApiKey, setShownApiKey] = useState<any>({});

  const toggleApiKey = (id: number) => {
    setShownApiKey((prevShownApiKey: any) => ({
      ...prevShownApiKey,
      [id]: !prevShownApiKey[id],
    }));
  };

  const [selectedRow, setSelectedRow] = useState<any>({});

  const selectRow = (row: any) => {
    setSelectedRow({});
    setSelectedRow((prevSelectedRow: { [x: string]: any }) => ({
      ...prevSelectedRow,
      [row.id]: !prevSelectedRow[row.id],
    }));
  };

  function renderApiKey(cell: any, rowId: number, width: number | string) {
    if (shownApiKey[rowId]) {
      return (
        <Text
          ellipsis={true}
          title={"click to copy"}
          onClick={() => copyApiKey(cell.value)}
          style={{ maxWidth: `${width}px` }}
        >
          {cell.value}
        </Text>
      );
    } else {
      return (
        <Text ellipsis={true} style={{ maxWidth: `${width}px` }}>
          {cell.value.replace(/./g, "*")}
        </Text>
      );
    }
  }

  // copy apikey
  const copyApiKey = (apikey: string) => {
    copy(apikey);
    message.success(`API Key copied to clipboard`);
  };

  // delete token
  const deleteToken = (apiName: string) => {
    let url = `api/delete-token/${apiName}`;
    axios
      .delete(url)
      .then((res) => {
        updateToken("delete", apiName);
        setSelectedRow({});
      })
      .catch((error) => {
        if (error.response.status === 401) {
          loginWithRedirection();
          return;
        } else if (error.response.status === 403) {
          message.warning(`Access denied`);
          return;
        }
        message.error(error.message || "Something went wrong");
      });
  };

  function showDeleteConfirm(apiName: string) {
    modal.confirm({
      title: `Are you sure, you want to delete this token?`,
      icon: <ExclamationCircleOutlined translate={undefined} />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteToken(apiName);
      },
      onCancel() {},
    });
  }

  const loginWithRedirection = () => {
    let redirectUrl = window.location.href;
    return <Navigate to={`/`} />;
  };

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    toggleRowSelected,
    rows,
    // preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const { authorizedAtoms } = useSelector(
    (state: RootStateOrAny) => ({
      authorizedAtoms: state.commonReducer.authorizedAtoms,
    }),
    shallowEqual
  );

  const isDeleteTokenAllow =
    authorizedAtoms?.["home-app-api-key-delete"]?.["allow"];

  return (
    <Fragment>
      <Row
        gutter={[16, 16]}
        align="middle"
        justify="space-between"
        style={{ marginBottom: "20px" }}
      >
        <Col span="24">
          <GlobalFilter
            // preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            updateToken={updateToken}
          />
        </Col>
      </Row>
      <Table {...getTableProps()} style={{ tableLayout: "auto" }}>
        <thead>
          {headerGroups.map((headerGroup: any, index: number) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, i: number) => {
                if (column.id !== "Action") {
                  return (
                    <th
                      width={column.width}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span className="sorting-arrow" key={i}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <CaretDownOutlined
                              style={{
                                fontSize: "12px",
                                marginLeft: "4px",
                                marginTop: "8px",
                              }}
                            />
                          ) : (
                            <CaretUpOutlined
                              style={{ fontSize: "12px", marginLeft: "4px" }}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  );
                } else {
                  return (
                    <th width={column.width} {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  );
                }
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows && rows.length > 0 ? (
            rows.map((row: any, i: number) => {
              prepareRow(row);
              if (row) {
                return (
                  <tr
                    className={selectedRow[row.id] ? "bg-light" : "bg-white"}
                    key={i}
                    {...row.getRowProps()}
                    onClick={() => {
                      toggleRowSelected(row.id);
                      selectRow(row);
                    }}
                  >
                    {row.cells.map((cell: any, index: number) => {
                      if (cell.column.Header === "Expiration") {
                        return (
                          <td {...cell.getCellProps()}>{renderTime(cell)}</td>
                        );
                      } else if (cell.column.Header === "Name") {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      } else if (cell.column.Header === "API key") {
                        return (
                          <td {...cell.getCellProps()}>
                            <code>
                              {renderApiKey(cell, row.id, cell.column.width)}
                            </code>
                          </td>
                        );
                      } else if (cell.column.Header === "Action") {
                        return (
                          <td {...cell.getCellProps()}>
                            <Space>
                              <Tooltip
                                title={
                                  shownApiKey[row.id]
                                    ? "Hide apikey"
                                    : "Show apikey"
                                }
                              >
                                <span
                                  onClick={() => toggleApiKey(row.id)}
                                  className="pointer"
                                >
                                  <FontAwesomeIcon
                                    color="#808080"
                                    icon={
                                      shownApiKey[row.id] ? faEyeSlash : faEye
                                    }
                                    fixedWidth
                                  />
                                </span>
                              </Tooltip>
                              <Tooltip
                                title={
                                  isDeleteTokenAllow
                                    ? "Delete apikey"
                                    : `${ACCESS_DENIED_MESSAGE}`
                                }
                              >
                                <Button
                                  type="text"
                                  disabled={!isDeleteTokenAllow}
                                  onClick={() =>
                                    showDeleteConfirm(row.values.name)
                                  }
                                  className="pointer"
                                  style={{ padding: "4px 0px" }}
                                >
                                  <FontAwesomeIcon
                                    color="#808080"
                                    icon={faTrashAlt}
                                    fixedWidth
                                  />
                                </Button>
                              </Tooltip>
                            </Space>
                          </td>
                        );
                      } else {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              }
              return null;
            })
          ) : (
            <tr>
              <td colSpan={2}>
                <NothingToShow />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Fragment>
  );
}

export default function Tokens(props: TokensPropTypes) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        // width: "20%",
      },
      {
        Header: "Type",
        accessor: "type",
        // width: "50",
      },
      {
        Header: "Expiration",
        accessor: "expiration",
        width: "20%",
      },
      {
        Header: "API key",
        accessor: "data.apikey",
        //width: "400",
      },
      {
        Header: "Action",
        accessor: "",
        width: "100",
        sortable: false,
        filterable: false,
      },
    ],
    []
  );

  return (
    <Fragment>
      <div className="table-container">
        <CustomTable
          columns={columns}
          data={props.data}
          updateToken={props.updateToken}
        />
      </div>
    </Fragment>
  );
}
