import "whatwg-fetch";
import parseLinkHeader from "http-link-header";
import message from "./message";
import * as CONST from "./Const";
import store from "../store";
import * as commonAction from "../actions/CommonAction";
import { v4 as uuidv4 } from "uuid";
import {
  clearLocalStorageKey,
  getAccessToken
} from "modern-auth";

export default async function fetchJson(
  method: string,
  url: string,
  pathName?: string,
  body?: any
) {
  const POROS_BASE_URL = store.getState().commonReducer.config?.porosBaseUrl;

  //const storeValue = JSON.parse(localStorage.getItem("userInfo") ?? "{}");
  const token = getAccessToken()
  const APIKEY = token;

  let BASE_URL = "api";
  if (POROS_BASE_URL && pathName === "poros") {
    BASE_URL = POROS_BASE_URL + "api";
  } else {
    BASE_URL = "api";
  }

  const opts: any = {
    method: method.toUpperCase(),
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Expires: "-1",
      Pragma: "no-cache",
      Authorization: `Bearer ${APIKEY}`,
      "dataos-correlation-id": uuidv4(),
    },
  };
  if (body) {
    opts.body = JSON.stringify(body);
  }

  let fetchUrl = BASE_URL + url;

  if (BASE_URL && url.substring(0, 1) !== "/") {
    fetchUrl = BASE_URL + "/" + url;
  }

  let response;
  try {
    response = await fetch(fetchUrl, opts);
    if (response.redirected) {
      window.location = response.url as (string | Location) & Location;
      return {};
    } else if (response.status === 401) {
      message.error(CONST.SESSION_EXPIRED);
      store.dispatch(commonAction.resetConfigDetails());
      //Clear local storage and reload page 
      clearLocalStorageKey("userInfo");
      const getModernOidcKey = Object.keys(localStorage).filter((x) =>
        x.startsWith("modern-")
      )[0];
      localStorage.removeItem(getModernOidcKey);
      window.location.reload();
    } else if (response.status === 403) {
      message.error(CONST.FORBIDDEN);
    } else if (response.status === 500) {
      message.error(CONST.SERVER_ERROR_MESSAGE);
    }
  } catch (error) {
    const title = "Network error";
    message.error(title);
    return {
      error: title,
    };
  }

  try {
    const json = await response.json();

    let link = response.headers.get("Link");
    const links = link && parseLinkHeader(link);

    if (response.ok) {
      return { data: json, links };
    }

    // The body is an error object with a .title at a minimum, possibly .detail and other props
    // To ease transition, we'll convert the error object into an error string for now
    // At some point front-end can be updated to handle error object
    return { error: json.title || json };
  } catch (error) {
    // An error parsing JSON. This is unexepected, log to console
    // Send a more generic message in response
    console.error(response);
    return {
      error: "Server responded not ok",
    };
  }
}

export const api = {
  put(url: string, body: any) {
    return fetchJson("PUT", url, "", body);
  },
  patch(url: string, body: any) {
    return fetchJson("PATCH", url, "", body);
  },
  delete(url: string) {
    return fetchJson("DELETE", url, "");
  },
  post(url: string, body: any) {
    return fetchJson("POST", url, "", body);
  },
  get(url: string, pathName?: string) {
    return fetchJson("GET", url, pathName);
  },
};
