import React, { useState } from "react";
import { ProfileDetailType } from "../../containers/AppContainer";
import { CustomAvatarEditor } from "./CustomAvatarEditor";
import { Image } from 'antd'

interface IProps{
  avatar?:string,
  userName?:string,
  setAvatar?:(avatar:string)=>void,
  userId:string,
  setProfileDetail:React.Dispatch<React.SetStateAction<ProfileDetailType>>,
  profileDetail:ProfileDetailType,
  isCurrentUser:boolean,
}

export const RenderAvatar = (props:IProps) => {
  const [isOpenImageModal, setImageModal] = useState(false);
  const {
    avatar,
    userName,
    setAvatar,
    userId,
    setProfileDetail,
    profileDetail,
    isCurrentUser,
  } = props;

  if (!isCurrentUser) {
    return (
      <div style={{marginBottom: "10px" }}>
         <Image 
        key={userName}
        height={227}
        width={227}
        preview={false}
        src={`data:image/png;base64, ${avatar}`}
        />
      </div>
    );
  }

  return (
    <>
      <div
        onClick={() => setImageModal(true)}
        style={{ cursor: "pointer", marginBottom: "10px" }}
      >
        <Image 
        key={userName}
        height={227}
        width={227}
        preview={false}
        src={`data:image/png;base64, ${avatar}`}
        />
      </div>
      {isOpenImageModal && (
        <CustomAvatarEditor
          isOpen={isOpenImageModal}
          setImageModal={setImageModal}
          avatar={avatar}
          setParentAvatar={setAvatar!}
          userId={userId}
          setProfileDetail={setProfileDetail}
          profileDetail={profileDetail}
        />
      )}
    </>
  );
};
