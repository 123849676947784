import * as CONST from "../utils/Const";
export type TimelineDataType = {
  timestamp: number;
  title: String;
  description: String;
};

export type TimelineAction =
  | { type: "FETCH_TIMELINE" }
  | {
      type: "FETCH_TIMELINE_SUCCESS";
      timeline: TimelineDataType[];
      timelineError?: string | null;
    }
  | {
      type: "FETCH_TIMELINE_FAILURE";
      timeline: null;
      timelineError: string | null | unknown;
    }
  | { type: "RESET_TIMELINE" };

export const fetchTimeline = (): TimelineAction => ({
  type: CONST.FETCH_TIMELINE,
});

export const fetchTimelineSuccess = (
  timeline: Array<TimelineDataType>,
): TimelineAction => {
  return {
    type: CONST.FETCH_TIMELINE_SUCCESS,
    timeline,
    timelineError: null,
  };
};
export const fetchTimelineFailure = (data: string | null | unknown): TimelineAction => ({
  type: CONST.FETCH_TIMELINE_FAILURE,
  timeline: null,
  timelineError: data,
});

export const restTimeline = (): TimelineAction => ({
  type: CONST.RESET_TIMELINE,
});
