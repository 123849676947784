import React from "react";
import { DatePicker } from "antd";

const CustomDatePicker: React.FC<{ onDateSelect: any }> = ({
  onDateSelect,
}) => {
  const disabledDate = (current: any) => {
    // Can not select days before today
    return current && current < new Date();
  };

  return (
    <DatePicker
      showTime
      format="YYYY-MM-DD HH"
      disabledDate={disabledDate}
      onOk={onDateSelect}
      style={{ width: "100%" }}
      allowClear={false}
      showNow={false}
    />
  );
};

export default CustomDatePicker;
