import { Button, Divider } from 'antd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LightLogo from "../../assets/dataOS-logo-common-light.svg";
import DarkLogo from "../../assets/dataOS-logo-common.svg";

const Page404 = (props: {currentTheme: string | null,showLogo?: boolean | undefined, setNotFound: (arg: boolean) => void}) => {
    const {currentTheme, showLogo , setNotFound} = props;

    useEffect(() => {
      setNotFound(true);
    }, []);
    const location = useLocation();
    return (
      <div className="not-found">
        { showLogo && 
        <>
        <img
          src={currentTheme === "dark" ? LightLogo : DarkLogo}
          height="30"
          alt="DATAOS"
        />
        <Divider
          style={{ width: "633px", minWidth: "633px", margin: "20px auto 0" }}
        />
        </>
        }
        <h1>
          <span
            style={{ fontSize: "20rem", lineHeight: "normal", opacity: 0.2 }}
          >
            404
          </span>
        </h1>
        <Divider
          style={{ width: "633px", minWidth: "633px", margin: "0 auto 20px" }}
        />
        <h2 style={{ fontSize: "32px", fontWeight: 100 }}>
          No match found for
        </h2>
        <code>
          <span style={{ fontSize: "13.3px", fontFamily: "Menlo" }}>
            {location.pathname}
          </span>
        </code>
        <div style={{ marginTop: 10}}>
        <Button size='small' href='/home' type='link'>Go To Homepage</Button>
        </div>
      </div>
    );
  };

  export default Page404