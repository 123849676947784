import { combineReducers } from "redux";
import commonReducer from "./commonReducer";
import provenanceReducer from "./provenanceReducer";
import userDetailsReducer from "./userDetailsReducer";
import appsDetailsReducer from "./appsDetailsReducer";

const RootReducer = combineReducers({
  commonReducer,
  provenanceReducer,
  userDetailsReducer,
  appsDetailsReducer,
});
export default RootReducer;
