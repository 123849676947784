import Moment from "react-moment";

class Utils {
  static getReactBasePath() {
    let path = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }/`;
    return path;
  }
  static dateTimeFormatterSeparator(dateVal:number) {
    let time = {};
    let date = {};
    time = <Moment format="hh:mm A" >{dateVal}</Moment>;
    date = <Moment format="MMMM DD, YYYY" >{dateVal}</Moment>;
    return { time, date };
  }

  static dateTimeFormatterGMT(dateVal) {
    let date = {};
    date = <Moment format="MMMM DD, YYYY HH:mm ZZ">{dateVal}</Moment>
    return date;
  }

  static dateFormatterFromNow(dateVal) {
    let date = {};
    date = <Moment fromNow>{dateVal}</Moment>
    return date;
  }

  static setCookie(name, value) {
    document.cookie = name +'='+ value +'; Path=/;';
  } 

  static deleteCookie(name) {
    document.cookie = name +'=; Path=/; Expires= Thu, 01 Jan 1970 00:00:01 GMT;';
  }
  
}

export default Utils;
