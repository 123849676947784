import React, { useState, useEffect } from "react";
import Loading from "../../common/loader";
import { Form, Input, Button, Tooltip, Space, Typography, App } from "antd";
import { isRequired, validateEmail } from "../../utils/validateField";
import { Navigate } from "react-router-dom";
import { ProfileDetailType } from "../../containers/AppContainer";
import { userDetailType } from "../UserProfile/RenderUserProfileEditMode";
import { api } from "../../utils/fetch-json";

const FormItem = Form.Item;
const { Text } = Typography;

let initialValues = {
  name: "",
  id: "",
  email: "",
  type: "",
  jobTitle: "",
  location: "",
  contactNo: "",
  linkedin: "",
  github: "",
  website: "",
  tags: [],
  bio: "",
};

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  labelAlign: "right" as const,
};
interface valuesProptype {
  name: string;
  id: string;
  email: string;
  type: string;
  jobTitle: string;
  location: string;
  contactNo: string;
  linkedin: string;
  github: string;
  website: string;
  bio: string;
}
export interface PropertiesType {
  key: string;
  value: string;
}

interface userDetailsType extends valuesProptype {
  properties?: Array<PropertiesType> | null;
  federated_connector_id?: string | null;
  federated_user_id?: string | null;
  tags?: Array<string> | null;
}
interface FormInputItemProps {
  label?: string;
  name?: string;
  rules?: any;
  disabled?: boolean;
  maxLength?: number;
  helptext?: string;
  preserve?: boolean;
}

interface UpdateUserProfileProps {
  userDetail?: userDetailType;
  setProfileDetail: React.Dispatch<React.SetStateAction<ProfileDetailType>>;
  profileDetail: ProfileDetailType;
  isCurrentUser?: boolean;
  updateSideBarUserDetail?: (updatedUserData: userDetailType) => void;
}
const FormInputItem = (props: FormInputItemProps) => {
  const { disabled, maxLength, helptext, rules } = props;
  return (
    <FormItem label={props.label}>
      <Space size={"middle"} align="start">
        <FormItem name={props.name} rules={rules}>
          <Input
            disabled={disabled}
            style={{ width: "300px", float: "left" }}
            maxLength={maxLength}
          />
        </FormItem>
        {!disabled && helptext && (
          <Tooltip title={helptext}>
            <Text className="text-muted mt-1 d-inline-block">{helptext}</Text>
          </Tooltip>
        )}
      </Space>
    </FormItem>
  );
};

export const UpdateUserProfile = (props: UpdateUserProfileProps) => {
  const [userDetail, setUserDetail] = useState<userDetailsType>(initialValues);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isFormUpdated, setFormUpdated] = useState(true);
  const { setProfileDetail, profileDetail, isCurrentUser } = props;
  const [form] = Form.useForm();
  const [loader, setloader] = useState(true);

  const { notification } = App.useApp();

  const convertPropertiesIntoJson = (userDetails: userDetailsType) => {
    const { properties } = userDetails;
    let otherUserDetails = initialValues;
    properties &&
      properties.map((property: PropertiesType) => {
        const { key, value } = property;
        otherUserDetails = { ...otherUserDetails, [key]: value };
        return property;
      });
    setUserDetail({ ...otherUserDetails, ...userDetails });
    setloader(false);
  };

  useEffect(() => {
    return convertPropertiesIntoJson(props.userDetail!.user as userDetailsType);
  }, []);

  useEffect(() => {
    form.setFieldsValue(userDetail);
  }, [form, userDetail]);

  const onFinish = (values: valuesProptype) => {
    setSubmitting(true);
    const {
      email,
      name,
      bio,
      jobTitle,
      location,
      contactNo,
      linkedin,
      github,
      website,
    } = values;
    let { id, tags, type, federated_connector_id, federated_user_id } =
      userDetail;

    const url = `update-user-profile`;

    let properties: Array<{ key: string; value: string }> = [];

    if (jobTitle && jobTitle !== "") {
      properties.push({ key: "jobTitle", value: jobTitle.trim() });
    }

    if (location && location !== "") {
      properties.push({ key: "location", value: location.trim() });
    }

    if (contactNo && contactNo !== "") {
      properties.push({ key: "contactNo", value: contactNo.trim() });
    }

    if (linkedin && linkedin !== "") {
      properties.push({ key: "linkedin", value: linkedin.trim() });
    }

    if (github && github !== "") {
      properties.push({ key: "github", value: github.trim() });
    }

    if (website && website !== "") {
      properties.push({ key: "website", value: website.trim() });
    }

    if (bio && bio !== "") {
      properties.push({ key: "bio", value: bio.trim() });
    }

    const body = {
      id,
      email,
      name,
      tags,
      type,
      properties,
      federated_connector_id,
      federated_user_id,
    };

    api
      .post(url, body)
      .then((res) => {
        convertPropertiesIntoJson(res.data);
        let { currentUser } = profileDetail!;
        currentUser = { ...currentUser, name, email };
        setProfileDetail({ ...profileDetail, currentUser });
        setSubmitting(false);
        setFormUpdated(true);
        notification.success({
          message: `Profile updated successfully`,
          placement: "bottomRight",
        });
        props.updateSideBarUserDetail!({ ...props.userDetail, user: res.data });
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.response.status === 400) {
          notification.error({
            message: `${error.response.data.title}`,
            placement: "bottomRight",
          });
        } else if (error.response.status === 401) {
          loginWithRedirection();
        }
      });
  };

  const loginWithRedirection = () => {
    let redirectUrl = window.location.href;
    return <Navigate to={`/`} />;
  };

  const onFieldsChange = (changedFields: any) => {
    const { name, value } = changedFields[0];
    form.setFieldsValue({
      [name[0]]: value.trimStart(),
    });
    setFormUpdated(false);
  };

  return (
    <>
      {loader ? (
        <Loading />
      ) : (
        <div className="user-profile">
          <Form
            {...layout}
            name="customized_form_controls"
            initialValues={userDetail}
            form={form}
            onFinish={(values) => onFinish(values)}
            onFieldsChange={(changedFields, allFields) =>
              onFieldsChange(changedFields)
            }
          >
            <FormInputItem
              label="Name"
              name="name"
              rules={isRequired()}
              disabled={false || !isCurrentUser}
              maxLength={50}
              // helptext={"Please input your name"}
              preserve
            />

            <FormItem label="Bio" name="bio">
              <Space size={"middle"} align="start">
                <FormItem name="bio">
                  <Input.TextArea
                    style={{
                      resize: "none",
                      width: "300px",
                    }}
                    maxLength={250}
                    showCount={true}
                    autoSize={false}
                    disabled={!isCurrentUser}
                    rows={5}
                  />
                </FormItem>
              </Space>
            </FormItem>

            <FormInputItem
              label="Email"
              name="email"
              rules={validateEmail()}
              disabled={false || !isCurrentUser}
              maxLength={30}
              // helptext={"Please input some text"}
            />
            <FormInputItem
              label="Contact Number"
              name="contactNo"
              disabled={false || !isCurrentUser}
              maxLength={50}
              // helptext={"Please input some text"}
            />
            <FormInputItem
              label="Job Title"
              name="jobTitle"
              disabled={false || !isCurrentUser}
              maxLength={50}
              // helptext={"Please input some text"}
            />
            <FormInputItem
              label="Location"
              name="location"
              disabled={false || !isCurrentUser}
              maxLength={50}
              // helptext={"Please input some text"}
            />
            <FormInputItem
              label="Linkedin"
              name="linkedin"
              disabled={false || !isCurrentUser}
              // helptext={"Please input some text"}
            />

            <FormInputItem
              label="Github"
              name="github"
              disabled={false || !isCurrentUser}
              // helptext={"Please input some text"}
            />

            <FormInputItem
              label="Website"
              name="website"
              disabled={false || !isCurrentUser}
              // helptext={"Please input some text"}
            />

            {isCurrentUser && (
              <FormItem wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                    disabled={isFormUpdated}
                  >
                    Submit
                  </Button>
                  <Button
                    // type="secondary"
                    onClick={() => {
                      setFormUpdated(true);
                      form.resetFields();
                    }}
                  >
                    Reset
                  </Button>
                </Space>
              </FormItem>
            )}
          </Form>
        </div>
      )}
    </>
  );
};
