import { takeLatest, all } from "@redux-saga/core/effects";

import * as CONST from "../utils/Const";
import { fetchTimeline } from "./TimelineSaga";
import { fetchConfigDetails } from './commonSaga';
import { fetchUserDetails } from './userDetailsSaga';
import { fetchAppsDetails } from './appsDetailsSaga';

function* rootSaga():any {
  yield all([
    yield takeLatest(CONST.FETCH_CONFIG_DETAILS, fetchConfigDetails),
    yield takeLatest(CONST.FETCH_TIMELINE, fetchTimeline),
    yield takeLatest(CONST.FETCH_USER_DETAILS, fetchUserDetails),
    yield takeLatest(CONST.FETCH_APPS_DETAILS, fetchAppsDetails),
  ])
}
export default rootSaga;
