import * as CONST from "../utils/Const";

const initialState = {
    userDetails: null,
    error: null,
};

const userDetailsReducer =  (state = initialState, action: { type: string; payload: any; }) => {
  switch (action.type) {
    case CONST.FETCH_USER_DETAILS:
      return {
        ...state,
        userDetails: null,
        error: null,
      };
    case CONST.FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        error: null,
      };
    case CONST.FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        userDetails: null,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default userDetailsReducer