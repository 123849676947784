import * as CONST from "../utils/Const";

const initialState = {
    appsDetails: null,
    error: null,
};

const appsDetailsReducer =  (state = initialState, action: { type: string; payload: any; }) => {
  switch (action.type) {
    case CONST.FETCH_APPS_DETAILS:
      return {
        ...state,
        appsDetails: null,
        error: null,
      };
    case CONST.FETCH_APPS_DETAILS_SUCCESS:
      return {
        ...state,
        appsDetails: action.payload,
        error: null,
      };
    case CONST.FETCH_APPS_DETAILS_FAILURE:
      return {
        ...state,
        appsDetails: [],
        error: action.payload,
      };
    default:
      return state;
  }
}

export default appsDetailsReducer