import * as CONST from "../utils/Const";

export function fetchAppsDetails(): any {
    return {
      type: CONST.FETCH_APPS_DETAILS,
    }
  }
  
  export function fetchAppsDetailsSuccess(data: any): any {
    return {
      type: CONST.FETCH_APPS_DETAILS_SUCCESS,
      payload: data
    }
  }
  
  export function fetchAppsDetailsFailure(data: any): any {
    return {
      type: CONST.FETCH_APPS_DETAILS_FAILURE,
      payload: data
    }
  }