import { notification } from "antd";

notification.config({
  placement: "bottomRight",
  duration: 3,
});

const simpleNotification: any = {};

["success", "error", "info", "warning", "warn"].forEach((action) => {
  simpleNotification[action] = (
    message: string,
    description: string | null = null,
    props: any = null
  ) => {
    return (notification as any)[action]({
      ...props,
      message,
      description,
      className: "antd-dark-notification",
    });
  };
});

export default {
  // export Ant's notification and replace actions
  ...notification,
  ...simpleNotification,
};
