import { createStore, applyMiddleware } from "redux";
import { logger } from "redux-logger";
import reducers from "./reducers";
import rootSaga from "./sagas";
import createSagaMiddleware from "@redux-saga/core";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducers,
  applyMiddleware(sagaMiddleware, logger as any)
);
sagaMiddleware.run(rootSaga);

export default store;
