import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Grid, Divider, App } from "antd";
import Loading from "../../common/loader";
import {
  useNavigate,
  useLocation,
  useParams,
  Navigate,
} from "react-router-dom";
import { UpdateUserProfile } from "../UpdateUserProfile";
import { UserSidebar } from "./UserSidebar";
import { api } from "../../utils/fetch-json";
import { ProfileDetailType } from "../../containers/AppContainer.js";
import { userDetailType } from "./RenderUserProfileEditMode.js";
import { RootStateOrAny, shallowEqual, useSelector } from "react-redux";
import Page404 from "../../common/PageNotFound";
const { useBreakpoint } = Grid;

interface IProps {
  setProfileDetail: React.Dispatch<React.SetStateAction<ProfileDetailType>>;
  profileDetail: ProfileDetailType;
  isCurrentUser: boolean;
}

const RenderUserProfileViewMode = (props: IProps) => {
  const { notification } = App.useApp();
  const [userDetail, setUserDetail] = useState<userDetailType | null>(null);
  const [usageData, setUsageData] = useState<any>(null);
  const navigate = useNavigate();
  const [loader, setloader] = useState(true);
  const [isLoadingUsageDetails, setloadingUsageDeatils] = useState(true);
  const [activeKey, setActiveKey] = useState("overview");
  const location = useLocation();
  const params = useParams<{ userId: string }>();
  const { setProfileDetail, profileDetail, isCurrentUser } = props;
  const [notFound, setNotFound] = useState(false);

  const screens = useBreakpoint();

  const currentTheme: string | null = localStorage.getItem("currentTheme");

  const setActiveKeyValue = () => {
    let path = location.pathname;
    if (path.includes(`/overview`) || path.includes(`/overview/`))
      setActiveKey("overview");
    else if (path.includes(`/tokens`) || path.includes(`/tokens/`))
      setActiveKey("tokens");
    else if (path.includes(`/usage`) || path.includes(`/usage/`))
      setActiveKey("usage");
    else setActiveKey("overview");
  };

  useEffect(() => {
    if (location.pathname) setActiveKeyValue();
  }, [location.pathname]);

  const onClickTab = (key: string) => {
    if (activeKey !== key) {
      setActiveKey(key);
      navigate(`/profile/${key}/${params.userId}`);
    }
  };

  const getUserDetails = async () => {
    const { userId } = params;
    try {
      const url = `/get-user-by-id?userId=${userId}`;
      const responseJson = await api.get(url, "");
      if (responseJson.data) {
        setUserDetail(responseJson.data);
        setloader(false);
      } else {
        if (responseJson.error === "Not Found") {
          setNotFound(true);
        }
        // handleError(responseJson.error);
        setloader(false);
      }
    } catch (error) {
      handleError(error);
      setloader(false);
    } finally {
      setloader(false);
    }
  };

  useEffect(() => {
    document.title = `Profile: ${
      activeKey.charAt(0).toUpperCase() + activeKey.slice(1)
    } | DataOS`;
    // document.title = `${(profileDetail.currentUser.name)} | DataOS`
  }, [activeKey]);

  const loginWithRedirection = () => {
    let redirectUrl = window.location.href;
    return <Navigate to={`/`} />;
  };

  const handleError = (error: any) => {
    if (error?.response?.status === 401) {
      loginWithRedirection();
    } else if (error?.response?.status === 403) {
      notification.warning({
        message: `Access denied`,
        placement: "bottomRight",
      });
    } else if (error?.response?.status === 404 || error?.code === 404) {
      setNotFound(true);
    }
  };

  const { user, config } = useSelector(
    (state: RootStateOrAny) => ({
      user: state.commonReducer.user,
      config: state.commonReducer.config,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (config) {
      getUserDetails();
      // getUsageDetails();
    }
  }, [config]);

  const setAvatar = (avatar: string) => {
    setUserDetail({ ...userDetail, avatars: { image_base64: avatar } });
  };

  const items = [
    {
      key: "overview",
      label: "Overview",
      children: (
        <>
          {" "}
          {userDetail && (
            <UpdateUserProfile
              userDetail={userDetail}
              setProfileDetail={setProfileDetail}
              profileDetail={profileDetail}
              isCurrentUser={isCurrentUser}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {loader ? (
        <Loading />
      ) : !notFound ? (
        <div className="user-profile">
          <Row style={{ position: "relative" }} wrap={false}>
            <Col flex="255px" id="catalog_left_section">
              {userDetail && (
                <UserSidebar
                  setAvatar={setAvatar}
                  userDetail={userDetail}
                  {...props}
                />
              )}
            </Col>
            {screens.sm && screens.md && (
              <Col flex="48px">
                <Divider
                  type="vertical"
                  style={{ height: "100%", margin: "0 0" }}
                />
              </Col>
            )}
            <Col flex="auto" id="catalog_right_section">
              <Tabs
                defaultActiveKey={activeKey}
                activeKey={activeKey}
                onChange={onClickTab}
                key={location.key}
                items={items}
              />
            </Col>
          </Row>
        </div>
      ) : (
        <Page404 currentTheme={currentTheme} setNotFound={setNotFound} />
      )}
    </>
  );
};

export default RenderUserProfileViewMode;
