import {
  fetchTimelineFailure,
  fetchTimelineSuccess,
} from "../actions/TimelineAction";
import { put } from "redux-saga/effects";



const data = [
  {
    "timestamp": 1640771275000,
    "title": "User created",
    "description": "The user nikshepmaknoor was created"
  },
  {
    "timestamp": 1640771375000,
    "title": "Tags assigned",
    "description": "Developer tag was assigned to nikshepmaknoor"
      
  },
  {
    "timestamp": 1640771575000,
    "title": "New column added",
    "description": "nikshepmaknoor added new column order_type to Enriched in-store orders dataset"
  },
  {
    "timestamp": 1640771775000,
    "title": "Column type updated",
    "description": "nikshepmaknoor updated the column type of column conusmer_no in Enriched in-store orders dataset from int to string"
  },
  {
    "timestamp": 1640771875000,
    "title": "Dataset description added",
    "description":"nikshepmaknoor added the description ‘In-store sales data joined with customer and product data’ to Enriched in-store orders dataset"
  },
  {
    "timestamp": 1640771975000,
    "title": "Dataset description updated",
    "description": "nikshepmaknoor updated the description of Enriched in-store orders dataset from ‘In-store sales data joined with customer and product data’ to ‘In-store sales data joined with customer and product data. Added customer demographics' "
  },
  {
    "timestamp": 1640772975000,
    "title": "Column removed",
    "description": "nikshepmaknoor removed the column customer_id from Enriched in-store orders"
  },
  {
    "timestamp": 1640773975000,
    "title": "Tag added to the dataset",
    "description": "nikshepmaknoor has added tag USA on Enriched in-store orders dataset"
  },
  {
    "timestamp": 1640774975000,
    "title": "Column description edited",
    "description": "nikshepmaknoor has added description ‘unique indentification of different orders. Length of the values are in range of 1-50’ for column order_id in Enriched in-store orders dataset"
 },
  {
    "timestamp": 1640775975000,
    "title": "Tag added to the column",
    "description": "nikshepmaknoor has added the tag dataos:f:pii to first_name column in Enriched in-store orders dataset"
  },
  {
    "timestamp": 1640776975000,
    "title": "Queried Dataset",
    "description": "nikshepmaknoor queried DC Info Source Data dataset"
  },
  {
    "timestamp": 1640777975000,
    "title": "Note added",
    "description": "nikshepmaknoor has added note ‘To generate unique customer_id concatenate customer_no and site_no’ to DC Info Source Data Dataset"
  },
  {
    "timestamp": 1640778975000,
    "title": "Query Published",
    "description": "nikshepmaknoor has published query retail_average_order_metric " 
  },
  {
    "timestamp": 1640788975000,
    "title": "Dashboard created",
    "description": "nikshepmaknoor has created In Store Annual Sales dashboard"
  },
  {
    "timestamp": 1640778975000,
    "title": "New quality assertions added",
    "description": "nikshepmaknoor added new quality assertions ‘cardinality(category) = 32, null(email) < 0.15’ to Enriched in-store orders dataset" 
  }
]

export function* fetchTimeline() {
  const result = { data: data.reverse(), error: null };
  try {
    if (result.data) {
      yield put(fetchTimelineSuccess(result.data));
    } else {
      yield put(fetchTimelineFailure(result.error));
    }
  } catch (error) {
    yield put(fetchTimelineFailure(error));
  }
}
