import { put } from 'redux-saga/effects'
import { fetchAppsDetailsFailure, fetchAppsDetailsSuccess } from '../actions/AppsDetailsAction';
import { api } from '../utils/fetch-json'

export function* fetchAppsDetails(action) {
  try {
    const url = `/apps`;
    const response = yield api.get(url, '');
    if (response && response.data) {
      yield put(fetchAppsDetailsSuccess(response.data))
} else if (response.error) {
      yield put(fetchAppsDetailsFailure(response.error))
    }
  } catch (error) {
    yield put(fetchAppsDetailsFailure(error))
  }
}