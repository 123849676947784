const requiredFieldRule = {
  required: true,
  message: "This field is required",
};

const validEmailRule = {
  type: "email",
  message: "Please enter a valid E-mail!",
};

const requiredFieldRuleForContact = {
  required: true,
  message: "",
};

export const isRequired = () => {
  return [requiredFieldRule];
};

export const validateEmail = () => {
  return [requiredFieldRule, validEmailRule];
};

const checkFieldLength = () => ({
  validator(_: any, value: string) {
    const pattern = /^[^-\s][a-zA-Z0-9_\s-]+$/;
    if (value && value !== "") {
      if (pattern.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Please enter a valid value"));
    }
    return Promise.reject(new Error("This field is required"));
  },
});

export const isValidLength = () => {
  return [requiredFieldRule, checkFieldLength];
};

const checkContactNumberValidOrNot = () => ({
  validator(_: any, value: string) {
    const pattern = /^\d+$/;
    if (value && value !== "") {
      if (pattern.test(value)) {
        return Promise.resolve();
      }

      return Promise.reject(new Error("Please enter a valid contact number"));
    }
    return Promise.reject(new Error("This field is required"));
  },
});

export const validContactNo = () => {
  return [requiredFieldRuleForContact, checkContactNumberValidOrNot];
};
