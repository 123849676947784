/**
 * @author Hanuman Singh Parihar
 * Date: Dec 20, 2021
 * Description: COMMON REDUCER FOR CONFIGS!
 *
 */
import * as CONST from "../utils/Const";

const initialState = {
  appVersion: "",
  installVersion: "",
  config: null,
  user: null,
  features: null,
  loading: false,
  isUnauthorized: false,
  authorizedAtoms: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CONST.FETCH_CONFIG_DETAILS:
      return {
        ...state,
        appVersion: "",
        installVersion: "",
        config: null,
        user: null,
        features: null,
        loading: true,
      };
    case CONST.FETCH_CONFIG_DETAILS_SUCCESS:
      return {
        ...state,
        appVersion: action.payload.version ? action.payload.version : "",
        installVersion: action.payload.installVersion
          ? action.payload.installVersion
          : "",
        user: action.payload.currentUser ? action.payload.currentUser : null,
        config: action.payload.config ? action.payload.config : null,
        features: action.payload.features ? action.payload.features : null,
        loading: false,
        isUnauthorized:
          action.payload.isUnauthorized === "Unauthorized" ? true : false,
      };
    case CONST.FETCH_CONFIG_DETAILS_FAILURE:
      return {
        ...state,
        appVersion: "",
        installVersion: "",
        config: null,
        user: null,
        features: null,
        loading: false,
        isUnauthorized: true,
      };
    case CONST.UPDATE_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case CONST.UPDATE_AUTHORIZED_ATOMS:
      return {
        ...state,
        authorizedAtoms: action.payload,
      };
    case CONST.RESET_CONFIG_DETAILS:
      return {
        ...state,
        appVersion: "",
        installVersion: "",
        config: null,
        user: null,
        features: null,
        loading: false,
        isUnauthorized: true,
        authorizedAtoms: null,
      };
    default:
      return state;
  }
}
