import React from "react";
import { NOTHING_TO_SHOW } from "../../utils/Const";

interface IProps{
  fontSize?:string | number;
  padding?:string | number;
  type?:string | number;
  text?:string | number;
}

const NothingToShow = (props:IProps) => {

  const fontSize = props.fontSize ? props.fontSize : "2.6rem";
  const style = {
    fontSize: fontSize,
    fontWeight: 300,
    opacity: 0.2,
    padding: props.padding || 0,
  };

  const smallScreenStyle = {
    fontSize: fontSize,
    fontWeight: 300,
    padding: "0px 20px",
    position: "fixed" as const,
    zIndex: 99999,
    background: "white",
    color: "#5252527a",
    width: "100%",
    top: "0px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center" as const,
  };

  return (
    <div style={props.type === "alert" ? smallScreenStyle : style} >
      { props.text ? props.text :  NOTHING_TO_SHOW }
    </div>
  );
};

export default NothingToShow;
