import { TimelineAction, TimelineDataType } from "../actions/TimelineAction";
import * as CONST from "../utils/Const";



type stateType = {
  timeline: TimelineDataType[] | null;
  timelineError: string | null | unknown;
  loading: boolean;
};
const initialState = {
  timeline: null,
  timelineError: null,
  loading: false,
};

export default function (
  state: stateType = initialState,
  action: TimelineAction
): stateType {
  switch (action.type) {
    case CONST.FETCH_TIMELINE:
      return {
        ...state,
        loading: true,
      };
    case CONST.FETCH_TIMELINE_SUCCESS:
      return {
        ...state,
        timeline: action.timeline,
        loading: false,
      };
    case CONST.FETCH_TIMELINE_FAILURE:
      return {
        ...state,
        loading: false,
        timelineError: action.timelineError,
      };
    case CONST.RESET_TIMELINE:
      return {
        ...state,
        timeline: null,
      };
    default:
      return state;
  }
}
