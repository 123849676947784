import { put } from 'redux-saga/effects'
import { fetchUserDetailsFailure, fetchUserDetailsSuccess } from '../actions/UserDetailsAction';
import { api } from '../utils/fetch-json'

export function* fetchUserDetails(action) {
  try {
    const { userId } = action
    const url = `/me?userId=${userId}`;
    const response = yield api.get(url, '');
    if (response && response.data) {
      yield put(fetchUserDetailsSuccess(response.data))
} else if (response.error) {
      yield put(fetchUserDetailsFailure(response.error))
    }
  } catch (error) {
    yield put(fetchUserDetailsFailure(error))
  }
}