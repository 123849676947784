import * as CONST from "../utils/Const";

export function fetchUserDetails(userId: string | undefined): any {
    return {
      type: CONST.FETCH_USER_DETAILS,
      userId
    }
  }
  
  export function fetchUserDetailsSuccess(data: any): any {
    return {
      type: CONST.FETCH_USER_DETAILS_SUCCESS,
      payload: data
    }
  }
  
  export function fetchUserDetailsFailure(data: any): any {
    return {
      type: CONST.FETCH_USER_DETAILS_FAILURE,
      payload: data
    }
  }