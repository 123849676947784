/**
 * @author Hanuman Singh Parihar
 * Date: Dec 20, 2021
 * Description: COMMON ACTIONS FOR CONFIGS!
 *
 */
import { currentUserType, ProfileDetailType } from "../containers/AppContainer";
import * as CONST from "../utils/Const";

export type CommonActionType =
  | { type: "FETCH_CONFIG_DETAILS" }
  | {
      type: "FETCH_CONFIG_DETAILS_SUCCESS";
      payload: ProfileDetailType;
    }
  | {
      type: "FETCH_CONFIG_DETAILS_FAILURE";
      payload: ProfileDetailType;
    }
  | { type: "UPDATE_CURRENT_USER"; payload: currentUserType }
  | { type: "RESET_CONFIG_DETAILS" }
  | { type: "UPDATE_AUTHORIZED_ATOMS"; payload: any };

export function fetchConfigDetails(): CommonActionType {
  return {
    type: CONST.FETCH_CONFIG_DETAILS,
  };
}

export function fetchConfigDetailsSuccess(
  data: ProfileDetailType
): CommonActionType {
  return {
    type: CONST.FETCH_CONFIG_DETAILS_SUCCESS,
    payload: data,
  };
}

export function fetchConfigDetailsFailure(
  error: ProfileDetailType
): CommonActionType {
  return {
    type: CONST.FETCH_CONFIG_DETAILS_FAILURE,
    payload: error,
  };
}

export function updateCurrentUser(data: currentUserType): CommonActionType {
  return {
    type: CONST.UPDATE_CURRENT_USER,
    payload: data,
  };
}

export function resetConfigDetails(): CommonActionType {
  return {
    type: CONST.RESET_CONFIG_DETAILS,
  };
}

export function updateAuthorizedAtoms(data: any): any {
  return {
    type: CONST.UPDATE_AUTHORIZED_ATOMS,
    payload: data,
  };
}
