import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, Upload, Slider, App } from "antd";
import AvatarEditor from "react-avatar-editor";
import axios from "axios";
import { Navigate } from "react-router-dom";
import message from "../../utils/message";
import * as commonAction from "../../actions/CommonAction";
import { ProfileDetailType } from "../../containers/AppContainer";
import { useAuth } from "modern-auth";

interface IProps {
  isOpen: boolean;
  setImageModal: React.Dispatch<React.SetStateAction<boolean>>;
  avatar?: string;
  setParentAvatar: (avatar: string) => void;
  userId: string;
  setProfileDetail: React.Dispatch<React.SetStateAction<ProfileDetailType>>;
  profileDetail: ProfileDetailType;
}

export const CustomAvatarEditor = ({
  isOpen,
  setImageModal,
  avatar,
  setParentAvatar,
  userId,
  setProfileDetail,
  profileDetail,
}: IProps) => {
  const auth = useAuth()
  const { notification } = App.useApp();
  const [scale, setScale] = useState(1);
  //const [ avatarPosition, setAvatarPositions ] = useState({x: 0, y: 0})
  const [newAvatar, setNewAvatar] = useState(avatar);
  const [isSubmitting, setSubmitting] = useState(false);
  const setEditorRef = useRef<AvatarEditor>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    //console.log("setEditorRef ------> ", setEditorRef);
  }, []);

  const handleOnChangeSlider = (value: number) => {
    setScale(value);
  };

  const handleSavePic = () => {
    const canvasScaled = setEditorRef.current!.getImageScaledToCanvas();
    const croppedImg = canvasScaled.toDataURL();
    setSubmitting(true);
    const url = `api/update-avatar`;

    const body = {
      id: userId,
      image_base64: croppedImg.split(",")[1],
    };

    //const userInfo = JSON.parse(localStorage.getItem("userInfo") ?? "{}");
    const  accessToken  = auth?.user?.access_token;

    const headers: any = {
      Authorization: `Bearer ${accessToken}`,
    };

    axios
      .put(url, body, { headers: headers })
      .then((res) => {
        let { currentUser } = profileDetail!;
        currentUser = { ...currentUser, avatar_base64: res.data.image_base64 };
        dispatch(commonAction.updateCurrentUser(currentUser));
        setProfileDetail({ ...profileDetail, currentUser });
        setParentAvatar(res.data.image_base64);
        setImageModal(false);
        setSubmitting(false);
        notification.success({
          message: `Profile photo updated successfully`,
          placement: "bottomRight",
        });
      })
      .catch((error) => {
        setSubmitting(false);
        notification.error({
          message: `${error.response.data.title}`,
          placement: "bottomRight",
        });
        if (error.response.status === 401) {
          loginWithRedirection();
        }
      });
  };

  const loginWithRedirection = () => {
    let redirectUrl = window.location.href;
    return <Navigate to={`/`} />;
  };

  const uploadProps: any = {
    name: "file",
    maxCount: 1,
    accept: ".jpg, .jpeg, .png",
    beforeUpload(file: { size: number }) {
      const isValidFileSIze = file.size < 1024 * 600;
      if (!isValidFileSIze) {
        message.error("Image must be smaller than 600KB!");
      }
      return isValidFileSIze;
    },
    onChange(info: {
      file: { status: string; originFileObj: Blob };
      fileList: any;
    }) {
      var reader = new FileReader();
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        reader.onloadend = function () {
          setNewAvatar((reader.result! as string).split(",")[1]);
        };
        reader.readAsDataURL(info.file.originFileObj);
      } else if (info.file.status === "error") {
        reader.onerror = function () {
          console.log("error------->", reader.error);
          
        };
      }
    },
  };

  const handleKeyPress = (evt: React.KeyboardEvent<HTMLDivElement>) => {
    evt.keyCode === 27 && setImageModal(false);
  };

  return (
    <>
      <Modal
        title="Profile photo"
        open={isOpen}
        onCancel={() => setImageModal(false)}
        footer={[
          <Button key="back" onClick={() => setImageModal(false)}>
            Cancel
          </Button>,
          <Button
            key="savePhoto"
            type="primary"
            onClick={() => handleSavePic()}
            loading={isSubmitting}
            style={{ marginRight: "8px" }}
          >
            Save Photo
          </Button>,
          <Upload {...uploadProps} key="uploadPhoto">
            <Button type="primary">Upload Photo</Button>
          </Upload>,
        ]}
      >
        <div
          style={{ textAlign: "center" }}
          onKeyPress={(evt) => handleKeyPress(evt)}
        >
          <AvatarEditor
            ref={setEditorRef}
            image={`data:image/png;base64, ${newAvatar}`}
            width={250}
            height={250}
            //border={50}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={scale}
            rotate={0}
            borderRadius={250 / (100 / 1000)}
          />
        </div>
        <Slider
          defaultValue={1}
          onChange={(value: number) => handleOnChangeSlider(value)}
          min={1}
          max={10}
        />
      </Modal>
    </>
  );
};
