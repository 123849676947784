import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { fetchTimeline, TimelineDataType } from "../../actions/TimelineAction";
import Utils from "../../utils/Utils";
import { Timeline, Space, Typography, Layout } from "antd";
import Loader from "../../common/loader";

const { dateTimeFormatterSeparator } = Utils;
const { Item } = Timeline;
const { Text, Paragraph } = Typography;
const { Content } = Layout;


const TimeLineView = () => {
  const dispatch = useDispatch();

  const { timeline, loading } = useSelector((state:RootStateOrAny) => ({
    timeline: state.provenanceReducer.timeline,
    //timelineError: state.provenanceReducer.timelineError,
    loading: state.provenanceReducer.loading,
  }));

  useEffect(() => {
    if (!timeline) {
      dispatch(fetchTimeline());
    }
  }, []);

  const dateTimeRenderer = (timestamp:number) => {
    const { date, time } = dateTimeFormatterSeparator(timestamp);
    return (
      <Space direction="vertical" size={1}>
        <>
          {date}
          {time}
        </>
      </Space>
    );
  };

  if (loading) return <Loader />;
  return (
    <>
      {timeline && (
        <div className="timeline-View">
          <Timeline mode="left">
            {timeline.map((item:TimelineDataType, index:number) => {
              const { timestamp, title, description } = item;
              return (
                <Item label={dateTimeRenderer(timestamp)} key={index}>
                  <Space direction="vertical" size={0}>
                    <Content style={{ width: "70%" }}>
                      <Text strong={true}>{title}</Text>
                      <Paragraph>{description}</Paragraph>
                    </Content>
                  </Space>
                </Item>
              );
            })}
          </Timeline>
        </div>
      )}
    </>
  );
};

export default TimeLineView;
